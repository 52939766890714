import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BottomErrMsg } from "component/basic/popup";
import { ContentsSection, InputSection } from "component/app/items";
import {InputItemBox, CustomSelect, TextAreaItem, DatePicer,} from "component/basic/formItems";
import { BtnBox, BtnItem } from "component/basic/btns";
import { Header } from "component/elements/header";
import bankData from "../../data/bank.json";
import moment from "moment";

const TempWorkerRegister = (props) => {
    const navigate = useNavigate();
    const bottomRef = useRef(null);

    const [isForeigner, setIsForeigner] =  useState(false);
    const [type, setType] =  useState('');
    const [name, setName] =  useState('');
    const [birth1, setBirth1] =  useState('');
    const [birth2, setBirth2] =  useState('');
    const [bank, setBank] =  useState('');
    const [bankAccount, setBankAccount] =  useState('');
    const [incomeCode, setIncomeCode] =  useState('');
    const [schoolExpenses, setSchoolExpenses] =  useState('');
    const [issueDate, setIssueDate] =  useState(['']);
    const [issueAmountType, setIssueAmountType] =  useState(['']);
    const [issueAmount, setIssueAmount] =  useState(['']);

    const [btnChk,setBtnChk] =  useState(true);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});

    const incomeApi = useGet({url:`/income?company_id=${localStorage.getItem("companyId")}&type=${type === '사업소득' ? 'business' : (type === '기타소득' ? 'etc' : '')}`, loginType:"login"});
    const incomeData = incomeApi?.data?.income_codes;
    
    function handleSubmit(){
        let formData = new FormData();
        formData.append("company_id", localStorage.getItem("companyId"));
        formData.append("type", type);
        formData.append("name", name);
        formData.append("registration_number", birth1 + birth2);
        formData.append("bank", bank);
        formData.append("bank_account", bankAccount);
        formData.append("is_foreigner", isForeigner ? 1 : 0);
        if (type === '기타소득' || type === '사업소득') {
            if (type === '사업소득') {
                formData.append("school_expenses", schoolExpenses);
            }
            formData.append("income_code", incomeCode);
            formData.append(`issue_date[0]`, issueDate[0]);
            formData.append("issue_amount_type[0]", issueAmountType[0]);
            formData.append("issue_amount[0]", issueAmount[0]);
        } else {
            for (let i = 0; i < issueDate?.length; i++) {
                formData.append(`issue_date[${i}]`, issueDate[i]);
                formData.append(`issue_amount_type[${i}]`, issueAmountType[i]);
                formData.append(`issue_amount[${i}]`, issueAmount[i]);
            }
        }

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: "/temp/worker/register",
            loginType: "login",
            success: (data) => {
                navigate(`/tempWorker?message=일용직 등록이 완료되었습니다.`);
            },
            err: (data) => {
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    useEffect(() => {
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [issueDate?.length]);

    useEffect(() => {
        if (type === '기타소득') {
            if (birth1 !== "" && birth2 !== "" && incomeCode !== "" && issueDate?.length > 0 && issueAmountType?.length > 0 && issueAmount?.length > 0) {
                setBtnChk(false)
            } else {
                setBtnChk(true)
            }
        } else if (type === '사업소득') {
            if (birth1 !== "" && birth2 !== "" && incomeCode !== "" && schoolExpenses !== "" && issueDate?.length > 0 && issueAmountType?.length > 0 && issueAmount?.length > 0) {
                setBtnChk(false)
            } else {
                setBtnChk(true)
            }
        } else if (type === '일용직 근무') {
            if (birth1 !== "" && birth2 !== "" && issueDate?.length > 0 && issueAmountType?.length > 0 && issueAmount?.length > 0) {
                setBtnChk(false)
            } else {
                setBtnChk(true)
            }
        }
    }, [type, birth1, birth2, incomeCode, schoolExpenses, issueDate, issueAmountType, issueAmount]);

    return (
        <>
            <Header
                pageBack={true}
                func={() => {
                    navigate(-1)
                }}
                headTitle="일시 근무자 등록"
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents btnContents">
                <InputSection addClass="registerSection">
                    <CustomSelect
                        inputTitle="구분 선택"
                        placeholder="선택"
                        value={type}
                        func={(name, val, subVal) => {
                            setType(val);
                        }}
                        disabled={false}
                        valKey="id"
                        nameKey="name"
                        options={[
                            {id: '기타소득', name: '기타소득'},
                            {id: '사업소득', name: '사업소득'},
                            {id: '일용직 근무', name: '일용직 근무'},
                        ]}
                    />
                    <InputItemBox
                        inputType="text"
                        inputTitle="이름"
                        placeholder="이름 입력"
                        value={name}
                        max={null}
                        regexp={null}
                        isCheckbox={true}
                        checkboxLabel="외국인"
                        checked={isForeigner}
                        checkboxFunc={() => setIsForeigner(!isForeigner)}
                        func={(value)=>{setName(value)}}
                    />
                    <div className="companyBtnTextBox mt-20">
                        <InputItemBox
                            addClass=""
                            boxAddClass="col2"
                            inputTitle="주민등록번호"
                            placeholder="000000"
                            regexp="number"
                            value={birth1}
                            func={(value) => {setBirth1(value);}}
                            disabled={false}
                            maxLength={6}
                        />
                        <div className="hyphen">-</div>
                        <InputItemBox
                            placeholder="0"
                            boxAddClass="col2"
                            value={birth2}
                            regexp="number"
                            func={(value) => {setBirth2(value);}}
                            disabled={false}
                            maxLength={1}
                        />
                    </div>
                    <CustomSelect
                        inputTitle="계좌번호"
                        placeholder="은행 선택"
                        value={bank}
                        func={(name,val,subVal) => {
                            setBank(val);
                        }}
                        disabled={false}
                        valKey="name"
                        nameKey="name"
                        options={bankData?.bankCades}
                    />
                    <InputItemBox
                        inputType="text"
                        boxAddClass="mb-20 m-0"
                        placeholder="계좌번호를 입력해주세요"
                        value={bankAccount}
                        regexp="number"
                        max={null}
                        func={(value)=>{setBankAccount(value)}}
                    />
                    {(type === '기타소득' || type === '사업소득') && (<>
                        <InputItemBox
                            inputType="date"
                            inputTitle="지급 일자"
                            addClass={"inputDate"}
                            placeholder="일자 선택"
                            value={issueDate[0]}
                            func={(value) => {
                                let newIssueDate = [...issueDate];
                                newIssueDate[0] = value;
                                setIssueDate(newIssueDate);
                            }}
                            max={null}
                            regexp={null}
                        />
                        <CustomSelect
                            inputTitle="소득별 코드"
                            placeholder="선택"
                            value={incomeCode}
                            func={(name,val,subVal) => {
                                setIncomeCode(val);
                            }}
                            regexp="number"
                            disabled={false}
                            valKey="id"
                            nameKey="name"
                            options={incomeData}
                        />
                        {type === '사업소득' && (
                            <InputItemBox
                                inputType="text"
                                inputTitle="학자금 공제"
                                placeholder="학자금 공제 금액"
                                value={schoolExpenses}
                                max={null}
                                regexp="number"
                                func={(value)=>{setSchoolExpenses(value)}}
                            />
                        )}
                        <CustomSelect
                            inputTitle="지급 금액"
                            placeholder="선택"
                            value={issueAmountType[0]}
                            func={(name,val,subVal) => {
                                let newIssueAmountType = [...issueAmountType];
                                newIssueAmountType[0] = val;
                                setIssueAmountType(newIssueAmountType);
                            }}
                            disabled={false}
                            valKey="id"
                            regexp="number"
                            nameKey="name"
                            options={[
                                {id: 'before', name: '세전'},
                                {id: 'after', name: '세후'}
                            ]}
                        />
                        <InputItemBox
                            inputType="text"
                            boxAddClass="mb-20 m-0"
                            placeholder="지급 금액"
                            value={issueAmount[0]}
                            max={null}
                            regexp="number"
                            func={(value)=>{
                                let newIssueAmount = [...issueAmount];
                                newIssueAmount[0] = value;
                                setIssueAmount(newIssueAmount);
                            }}
                        />
                    </>)}
                    {type === '일용직 근무' && (<>
                        {issueDate?.map((date, dateKey) => (
                            <>
                                <div className="inputButtonBox">
                                    <InputItemBox
                                        inputType="date"
                                        inputTitle={dateKey === 0 ? `지급 일자` : <>&nbsp;</>}
                                        boxAddClass="mb-10"
                                        addClass={"inputDate"}
                                        placeholder="일자 선택"
                                        value={date}
                                        func={(value) => {
                                            let newIssueDate = [...issueDate];
                                            newIssueDate[dateKey] = value;
                                            setIssueDate(newIssueDate);
                                        }}
                                        max={null}
                                        regexp={null}
                                    />
                                    <button onClick={() => {
                                        if (dateKey === 0) {
                                            setIssueDate(() => issueDate.concat(""))
                                            setIssueAmountType(() => issueAmountType.concat(""))
                                            setIssueAmount(() => issueAmount.concat(""))
                                        } else {
                                            let newIssueDate = [...issueDate]; newIssueDate.splice(dateKey, 1); setIssueDate(newIssueDate);
                                            let newIssueAmountType = [...issueAmountType]; newIssueAmountType.splice(dateKey, 1); setIssueAmountType(newIssueAmountType);
                                            let newIssueAmount = [...issueAmount]; newIssueAmount.splice(dateKey, 1); setIssueAmount(newIssueAmount);
                                        }
                                    }} className="">
                                        <img src={`${dateKey === 0 ? '/assets/images/basic/plusButton.svg' : '/assets/images/basic/minusButton.svg'}`}/>
                                    </button>
                                </div>
                                <div className="inputSelectBox">
                                    <CustomSelect
                                        placeholder="선택"
                                        boxAddClass="m-0"
                                        value={issueAmountType[dateKey]}
                                        func={(name,val,subVal) => {
                                            let newIssueAmountType = [...issueAmountType];
                                            newIssueAmountType[dateKey] = val;
                                            setIssueAmountType(newIssueAmountType);
                                        }}
                                        disabled={false}
                                        valKey="id"
                                        nameKey="name"
                                        options={[
                                            {id: 'before', name: '세전'},
                                            {id: 'after', name: '세후'}
                                        ]}
                                    />
                                    <InputItemBox
                                        inputType="text"
                                        boxAddClass="m-0"
                                        placeholder="지급 금액"
                                        value={issueAmount[dateKey]}
                                        max={null}
                                        regexp="number"
                                        func={(value)=>{
                                            let newIssueAmount = [...issueAmount];
                                            newIssueAmount[dateKey] = value;
                                            setIssueAmount(newIssueAmount);
                                        }}
                                    />
                                </div>
                            </>
                        ))}
                    </>)}
                </InputSection>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={"작성하기"}
                        disabled={btnChk}
                        func={() => { handleSubmit() }}
                    />
                </BtnBox>
                <div ref={bottomRef}/>
            </ContentsSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

export default TempWorkerRegister;