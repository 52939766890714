import * as fatchSet from "../../api/api";
import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BottomErrMsg } from "component/basic/popup";
import { ContentsSection, InputSection } from "component/app/items";
import { CustomSelect, TextAreaItem, BottomPopup, DatePicerRange } from "component/basic/formItems";
import { BtnBox, BtnItem } from "component/basic/btns";
import { Header } from "component/elements/header";
import moment from "moment";
import useGet from "../../api/useGet";

const Request = (props) => {
    const navigate = useNavigate();

    const [month, setMonth] =  useState(moment().format("YYYY-MM"));
    const [beginDate, setBeginDate] =  useState('');
    const [endDate, setEndDate] =  useState('');
    const [dayOffs, setDayOffs] =  useState([]);
    const [memo, setMemo] =  useState('');
    const [disabledDate, setDisabledDate] =  useState([]);
    const [completePopup, setCompletePopup] =  useState(false);

    const [btnChk,setBtnChk] =  useState(true);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});
    const [minDate, setMinDate] =  useState('');

    const dayOffTypeApi = useGet({url:`/day/off/type?company_id=${localStorage.getItem("companyId")}`, loginType:"login"});
    const dayOffType = dayOffTypeApi?.data?.day_off_type;

    const dayOffTAvailableApi = useGet({url:`/day/off/available?company_id=${localStorage.getItem("companyId")}&month=${month}`, loginType:"login"});
    const dayOffTAvailable = dayOffTAvailableApi?.data?.dates;

    const dayOffTDateAvailableApi = useGet({url:`/day/off/date/available?company_id=${localStorage.getItem("companyId")}`, loginType:"login"});
    const dayOffTDateAvailable = dayOffTDateAvailableApi?.data?.company_day_off_times;

    useEffect(() => {
        if (dayOffTypeApi) {
            setMinDate(dayOffTypeApi?.data?.salary_history_info?.max_month)
        }
    }, [dayOffTypeApi])

    function handleSubmit(){
        let formData = new FormData();
        formData.append("company_id", localStorage.getItem("companyId"));
        for (let i = 0; i < dayOffs?.length; i++) {
            formData.append(`date[${i}]`, dayOffs[i]?.date);
            formData.append(`type[${i}]`, dayOffs[i]?.type);
        }
        formData.append("memo", memo);

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: "/day/off/request",
            loginType: "login",
            success: (data) => {
                setCompletePopup(true)
            },
            err: (data) => {
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    useEffect(() => {
        if (dayOffTAvailable) {
            const newDisabledDates = [];
            dayOffTAvailable?.map((dayOffTAvailable_) => {
                newDisabledDates.push(new Date(dayOffTAvailable_))
            })

            setDisabledDate(newDisabledDates)
        }
    }, [dayOffTAvailable]);

    useEffect(() => {
        if (beginDate !== "" && endDate !== "" && memo !== "" && generateDateRange(beginDate, endDate)?.length === dayOffs?.length) {
            setBtnChk(false)
        } else {
            setBtnChk(true)
        }
    }, [beginDate, endDate, dayOffs, memo]);

    function getOptions(date) {
        const options = [
            { id: 'day', name: `연차 1일 (8시간)` },
            { id: 'unpaid_day', name: `무급 연차 1일 (8시간)` },
        ];

        const dayCode = moment(date).day();

        if (!dayOffTAvailableApi?.data?.part_time_dates?.some(item => item === date)) {
            if (dayOffTDateAvailable?.some(item => item.type === 'morning' && item.day_code === dayCode)) {
                options.push({id: 'morning', name: "오전반차 | " + getTime(date, (dayOffTypeApi?.data?.company_morning_day_off_times ? dayOffTypeApi?.data?.company_morning_day_off_times : []), 'morning')});
                options.push({id: 'unpaid_morning', name: "무급 오전반차 | " + getTime(date, (dayOffTypeApi?.data?.company_morning_day_off_times ? dayOffTypeApi?.data?.company_morning_day_off_times : []), 'morning')});
            }
            if (dayOffTDateAvailable?.some(item => item.type === 'afternoon' && item.day_code === dayCode)) {
                options.push({id: 'afternoon', name: "오후반차 | " + getTime(date, (dayOffTypeApi?.data?.company_afternoon_day_off_times ? dayOffTypeApi?.data?.company_afternoon_day_off_times : []), 'afternoon')});
                options.push({id: 'unpaid_afternoon', name: "무급 오후반차 | " + getTime(date, (dayOffTypeApi?.data?.company_afternoon_day_off_times ? dayOffTypeApi?.data?.company_afternoon_day_off_times : []), 'afternoon')});
            }
        }

        return options
    }

    return (
        <>
            <Header
                pageBack={true}
                func={() => {
                    navigate(-1)
                }}
                headTitle="휴가 신청"
                centerTitle={true}
            />
            <ContentsSection addClass="headerContents btnContents">
                <InputSection addClass="registerSection mt-20">
                    <DatePicerRange
                        inputType="text"
                        inputTitle="휴가 신청일"
                        boxAddClass="inputGroup"
                        placeholder="날짜 선택"
                        value={beginDate + ' ~ ' + endDate}
                        disabledDate={disabledDate ? disabledDate : []}
                        monthFunc={(date) => {setMonth(moment(date).format("YYYY-MM"))}}
                        max={null}
                        minDate={minDate}
                        regexp={null}
                        func={(beginDate, endDate)=> { setBeginDate(moment(beginDate).format("YYYY-MM-DD")); setEndDate(moment(endDate).format("YYYY-MM-DD")); }}
                    />
                    {(beginDate && endDate) && (<>
                        {generateDateRange(beginDate, endDate)?.map((selectDate, selectDateKey) => (
                            <CustomSelect
                                addClass=""
                                inputTitle={`연차 형태 | ${moment(selectDate)?.format("YYYY-MM-DD")} (${moment(selectDate).format('dddd').replace('요일', '')})`}
                                placeholder="선택"
                                value={dayOffs[selectDateKey]?.type}
                                func={(name, val, subVal) => {
                                    const newDayOffs = [...dayOffs]
                                    if (!newDayOffs[selectDateKey]) {
                                        newDayOffs[selectDateKey] = {};
                                        newDayOffs[selectDateKey].date = selectDate
                                    }
                                    newDayOffs[selectDateKey].type = val
                                    setDayOffs(newDayOffs)
                                }}
                                disabled={false}
                                valKey="id"
                                nameKey="name"
                                options={getOptions(selectDate)}
                            />
                        ))}
                    </>)}
                    <TextAreaItem
                        inputTitle="메모"
                        inputName=""
                        placeholder="내용을 입력해주세요"
                        max={500}
                        maxChk={false}
                        value={memo}
                        func={(value) => setMemo(value)}
                    />
                </InputSection>
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={"신청하기"}
                        disabled={btnChk}
                        func={() => { handleSubmit() }}
                    />
                </BtnBox>
            </ContentsSection>
            <BottomPopup
                open={completePopup}
                title="휴가 신청 완료"
                subTitle={<div>휴가 신청이 완료되었습니다.<br/>신청 결과는 관리자 승인 후 확인 가능합니다.</div>}
                btn1Text="확인"
                btn1Func={() => { navigate(`/dayoff`); }}
            />
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

export default Request;

const generateDateRange = (start, end) => {
    let startDate = moment(start, "YYYY-MM-DD");
    const endDate = moment(end, "YYYY-MM-DD");
    const dates = [];

    while (startDate <= endDate) {
        dates.push(startDate.format('YYYY-MM-DD'));
        startDate = startDate.add(1, 'days');
    }

    return dates;
};

function getTime(date, data, type) {
    const dayCode = moment(date).format('d');

    const findTime = data.find((value) => value.day_code === Number(dayCode))
    if (findTime && (findTime?.begin_time && findTime?.end_time)) {
        const start = findTime?.begin_time.split(':')
        const end = findTime?.end_time.split(':')

        if (findTime?.begin_time > findTime?.end_time) {
            end[0] = parseInt(end[0])
            end[0] += 24
        }

        let hour = end[0] - parseInt(start[0]);
        let minute = parseInt(end[1]) - parseInt(start[1]);


        if (minute < 0) {
            hour -= 1
            if (minute === -60) {
                minute = 0
            } else {
                minute *= -1
            }
        }

        const hourDiff = hour?.toString()?.padStart(2, '0') + ':' + minute?.toString()?.padStart(2, '0')

        return findTime?.begin_time + ':' + findTime?.end_time + ' (' + hourDiff + ')'
    } else {
        if (type === 'morning') {
            return '09:00 ~ 13:00'
        } else {
            return '14:00 ~ 18:00'
        }
    }

}