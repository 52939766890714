import useGet from "api/useGet";
import * as fatchSet from "../../api/api";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import {useNavigate, useParams} from "react-router-dom";
import { BottomErrMsg } from "component/basic/popup";
import { ContentsSection, InputSection } from "component/app/items";
import {InputItemBox, CustomSelect, TextAreaItem, BottomPopup,} from "component/basic/formItems";
import { BtnBox, BtnItem } from "component/basic/btns";
import { Header } from "component/elements/header";
import moment from "moment";

const TodoModify = (props) => {
    const navigate = useNavigate();
    const bottomRef = useRef(null);
    const param = useParams();
    const id = param.id

    const [title, setTitle] =  useState('');
    const [contents, setContents] =  useState('');
    const [date, setDate] =  useState('');
    const [time, setTime] =  useState('');
    const [useAlarm, setUseAlarm] =  useState(false);
    const [departmentId, setDepartmentId] =  useState('');
    const [positionId, setPositionId] =  useState('');
    const [companyUserIds, setCompanyUserIds] =  useState([]);
    const [deleteCompanyUserIds, setDeleteCompanyUserIds] =  useState([]);

    const [deletePopupOpen,setDeletePopupOpen] =  useState(false);
    const [keyword,setKeyword] =  useState('');
    const [btnChk,setBtnChk] =  useState(true);
    const [bottomMsgData,setBottomMsgData] =  useState({chk : 0});
    const [inputErr,setInputErr] =  useState(false);

    const todoApi = useGet({url:`/todolist/detail?company_id=${localStorage.getItem("companyId")}&todolist_id=${id}`, loginType:"login"});
    const todoData = todoApi?.data?.todolist;

    const userApi = useGet({url:`/user/all?company_id=${localStorage.getItem("companyId")}&department_id=${departmentId}&position_id=${positionId}&keyword=${keyword}`, loginType:"login"});
    const userData = userApi?.data?.users;

    const departmentApi = useGet({url:`/department?company_id=${localStorage.getItem("companyId")}`, loginType:"login"});
    const departmentData = departmentApi?.data?.departments;

    const positionApi = useGet({url:`/position?company_id=${localStorage.getItem("companyId")}&department_id=${departmentId}`, loginType:"login"});
    const positionData = positionApi?.data?.positions;

    useEffect(() => {
        setTitle(todoData?.title)
        setContents(todoData?.contents)
        setDate(moment(todoData?.alarm_date).format("YYYY-MM-DD"))
        setTime(moment(todoData?.alarm_date).format("HH:mm"))
        setUseAlarm(todoData?.use_alarm)
        setCompanyUserIds(todoApi?.data?.todolist_users)
    }, [todoData]);

    const generateTimeSlots = () => {
        const times = [];
        for (let hours = 0; hours < 24; hours++) {
            for (let minutes = 0; minutes < 60; minutes += 30) {
                const time = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
                times.push({id: time, name: time});
            }
        }
        return times;
    };

    const timeSlots = generateTimeSlots();

    function handleDelete() {
        fatchSet.FatchApi({
            type:"DELETE",
            loginType:"login",
            url: `/todolist/delete?company_id=${localStorage.getItem("companyId")}&todolist_id=${id}`,
            success: (data) => {
                navigate(`/todo`);
            },
            err: (data) => {
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    function handleSubmit(){
        let formData = new FormData();
        formData.append("company_id", localStorage.getItem("companyId"));
        formData.append("todolist_id", id);
        formData.append("title", title);
        formData.append("contents", contents);
        formData.append("use_alarm", useAlarm ? 1 : 0);
        formData.append("alarm_date", date + ' ' + time + ':00');
        for (let i = 0; i < companyUserIds?.length; i++) {
            if (!companyUserIds[i]?.id) {
                formData.append(`company_user_ids[${i}]`, companyUserIds[i]?.user_company_id);
            }
        }
        for (let i = 0; i < deleteCompanyUserIds?.length; i++) {
            formData.append(`delete_company_user_ids[${i}]`, deleteCompanyUserIds[i]);
        }

        fatchSet.FatchApi({
            type:"POST",
            formDataItem: formData,
            url: "/todolist/modify",
            loginType: "login",
            success: (data) => {
                navigate(`/todo`);
            },
            err: (data) => {
                if(data.data || data.alert){
                    setBottomMsgData({
                        text : data.alert||"",
                        chk : bottomMsgData.chk + 1
                    });
                }
            }
        })
    }

    useEffect(() => {
        if (bottomRef.current && userData?.length > 0) {
            bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [userData]);

    useEffect(() => {
        if (title !== "" && contents !== "" && date !== "") {
            setBtnChk(false)
        } else {
            setBtnChk(true)
        }
    }, [title, contents, date]);

    return (
        <>
            <Header
                pageBack={true}
                func={() => {
                    navigate(-1)
                }}
                headTitle="할 일 수정"
                centerTitle={true}
                children={<button type="button" className="btnClose btnHeaderRight" onClick={(e) => { setDeletePopupOpen(true) }}><div className="headerRightButton">삭제</div></button>}
            />
            <ContentsSection addClass="headerContents btnContents">
                <InputSection addClass="registerSection">
                    <InputItemBox
                        inputType="text"
                        inputTitle="할 일 제목"
                        addClass={inputErr ? "err" : ""}
                        placeholder="제목을 입력해주세요"
                        value={title}
                        max={null}
                        regexp={null}
                        func={(value)=>{setTitle(value)}}
                    />
                    <TextAreaItem
                        inputTitle="내용"
                        inputName=""
                        placeholder="내용을 입력해주세요"
                        max={500}
                        maxChk={false}
                        value={contents}
                        func={(value) => setContents(value)}
                    />
                    <InputItemBox
                        inputType="date"
                        inputTitle="기간 및 시간 설정"
                        addClass={"inputDate"}
                        placeholder="기간 선택"
                        isCheckbox={true}
                        checkboxLabel="알림 여부"
                        checked={useAlarm}
                        checkboxFunc={() => setUseAlarm(!useAlarm)}
                        value={date}
                        func={(value) => { setDate(value) }}
                        max={null}
                        regexp={null}
                    />
                    <CustomSelect
                        boxAddClass="mb-20 m-0"
                        placeholder="시간 설정 (선택)"
                        value={time}
                        func={(name,val,subVal) => {
                            setTime(val);
                        }}
                        disabled={false}
                        valKey="id"
                        nameKey="name"
                        options={timeSlots}
                    />
                    <div className="companyBtnTextBox">
                        <CustomSelect
                            addClass=""
                            boxAddClass="col2"
                            inputTitle="대상 선택"
                            placeholder="부서 선택"
                            value={departmentId}
                            func={(name,val,subVal) => {
                                setDepartmentId(val);
                            }}
                            disabled={false}
                            valKey="id"
                            nameKey="name"
                            options={departmentData ? departmentData : []}
                        />
                        <CustomSelect
                            placeholder="직급 선택"
                            boxAddClass="col2"
                            value={positionId}
                            func={(name,val,subVal) => {
                                setPositionId(val);
                            }}
                            disabled={false}
                            valKey="id"
                            nameKey="name"
                            options={positionData?.length > 0 ? positionData : []}
                        />
                    </div>
                    {userData?.length > 0 && (
                        <div className="searchSelectBox">
                            <div className="searchSelectItem" onClick={() => {
                                userData?.map((user) => {
                                    setCompanyUserIds((prevCompanyUserIds) => {
                                        if (prevCompanyUserIds.some(item => item.id === user?.id)) {
                                            return prevCompanyUserIds
                                        }

                                        return [...prevCompanyUserIds, {id: user?.id, name: user?.name}]
                                    })
                                })
                            }}>전체 선택</div>
                            {userData?.map((user) => (
                                <div className="searchSelectItem" onClick={() => {
                                    setCompanyUserIds((prevCompanyUserIds) => {
                                        if (prevCompanyUserIds.some(item => item.user_company_id === user?.id)) {
                                            return prevCompanyUserIds
                                        }

                                        return [...prevCompanyUserIds, {user_company_id: user?.id, name: user?.name}]
                                    })
                                }}>{user?.name}</div>
                            ))}
                        </div>
                    )}
                    <InputItemBox
                        inputType="text"
                        boxAddClass="m-0"
                        addClass={(inputErr ? "err" : "")}
                        addInputSetClass="search"
                        placeholder="이름으로 검색"
                        value={keyword}
                        max={null}
                        regexp={null}
                        func={(value)=>{setKeyword(value)}}
                    />
                    <div className="positionTagBox">
                        {companyUserIds?.map((companyUser, companyUserKey) => (
                            <div className="positionTag">{companyUser?.name} <button onClick={() => {
                                if (companyUser?.id) {
                                    setDeleteCompanyUserIds(() => deleteCompanyUserIds.concat(companyUser?.id));
                                }

                                let newCompanyUserIds = [...companyUserIds];
                                newCompanyUserIds.splice(companyUserKey, 1);
                                setCompanyUserIds(newCompanyUserIds);
                            }}><img src={`/assets/images/basic/close.svg`}/></button></div>
                        ))}
                    </div>
                </InputSection>
                <BottomPopup
                    open={deletePopupOpen}
                    title="게시글을 삭제하시겠습니까?"
                    subTitle="삭제한 게시글은 복구되지 않습니다."
                    btn1Text="취소"
                    btn2Text="삭제하기"
                    btn1Func={() => { setDeletePopupOpen(false) }}
                    btn2Func={() => { handleDelete() }}
                />
                <BtnBox
                    boxType="fixed"
                    addClass=""
                >
                    <BtnItem
                        addClass=""
                        contents={"작성하기"}
                        disabled={btnChk}
                        func={() => { handleSubmit() }}
                    />
                </BtnBox>
                <div ref={bottomRef}/>
            </ContentsSection>
            <BottomErrMsg
                text={bottomMsgData.text ? bottomMsgData.text : ""}
                chk={bottomMsgData.chk}
            />
        </>
    );
};

export default TodoModify;